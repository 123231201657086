@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

:root {
  --Primary-100: #fdd4d7;
  --Primary-200:#FBAAB9;
  --Primary-500: #df2b82;
  --Primary-300: #f57ea0;
  --Primary-600: #bf1f7c;
  --Grey-400:#7B7B7B;
  --Grey-500: #585858;
  --White: #fff;
  --Grey-300: #bdbdbd;
  --Background: #040926;
  --Black: #000;
  --brown: #260417;
  --blue: #68a4ff;
  --green: #23f600;
  --green-light: #59ce4e;
  --border-image-200: linear-gradient(180deg, #EB5D93, #1A1B3A4D);
  --border-image-100: linear-gradient(180deg, #eb5d93, #373999);
  --bg-liner: linear-gradient(180deg, #F1A6C1 0%, #860C41 100%);
  --bg-green: #04260D;
  --bg-pink:#260417;
}

body {
  background-color: var(--Background);
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/*pagination*/
.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.pagination li {
  cursor: pointer;
}

.pagination a {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--Primary-500);
  border-radius: 50%;
  color: var(--White);
  font-size: 16px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: 400    ;
}

.pagination .active a {
  background-color: var(--Primary-500);
  color: white;
}

.pagination-disabled {
  opacity: 0.5; 
  pointer-events: none; 
  
}
.pagination-disabled a {
  border: 1px solid var(--Grey-500);
 
}

